import React, { useEffect } from 'react'
import { Button, Col, Container, Row } from 'reactstrap'
import sampleImage from "../../assets/images/users/avatar-2.jpg"
import { Link, useNavigate } from 'react-router-dom'
import { useDispatch } from 'react-redux'
import { getFeedbackList } from 'store/actions'
import moment from 'moment'
import { useSelector } from 'react-redux'
import { toast } from 'react-toastify'
const FeedBack = () => {
    const navigate = useNavigate();
    const dispatch = useDispatch();

    const { feedbacklist, errors, successMsg } = useSelector(state => state.FeedBackReducer)
    useEffect(() => {
        if (errors && errors !== null) {
            toast.error(errors)
        }
        if (successMsg && successMsg !== null) {
            toast.success(successMsg)
        }
    }, [errors, successMsg])

    useEffect(() => {
        dispatch(getFeedbackList())
    }, [])

    return (
        <div className='page-content'>

            <div className="chat-contacts">
                {feedbacklist && Array.isArray(feedbacklist) && feedbacklist.length > 0 && feedbacklist.map((feedback, index) => (
                    <Link
                        key={index}
                        to={"/feedback/chats"}
                        state={{
                            conversationID: feedback.conversation_id,
                            chatSubject: feedback.subject
                        }}
                        className="chat-contact-link"
                    >
                        <Row className='chat-contact'>
                            {/* <Col xs={2} className='p-1 d-flex align-items-center justify-content-center'>
                            <div className='chat-contact-initial'>
                                {feedback.subject.charAt(0).toUpperCase()}
                            </div>
                        </Col> */}
                            <Col xs={12} className='d-flex flex-column justify-content-center'>
                                <div className='w-100'>
                                    <div className='d-flex justify-content-between'>
                                        <p className='p-0 m-0 fw-bold chat-contact-subject'>
                                            {feedback.subject}
                                        </p>
                                        <p className='p-0 m-0 text-secondary chat-contact-date'>
                                            {moment(feedback.createdDate).format("DD/MM/YYYY")}
                                        </p>
                                    </div>
                                    <div className=''>
                                        <p className='p-0 m-0 text-secondary chat-contact-to'>
                                            To: {feedback.msg_to}
                                        </p>
                                    </div>
                                </div>
                            </Col>
                        </Row>
                    </Link>
                ))}
            </div>
            {
                localStorage.getItem("group_id") && localStorage.getItem("group_id") == 2 &&

                <div className="fixed-bottom" style={{ zIndex: 0, marginBottom: "6%" }}>
                    <Button className='btn-rounded mb-5 me-3 btn-lg float-end' color='primary' onClick={() => navigate("/feedback/create")}>New Feedback </Button>
                </div>
            }
        </div>
    )
}

export default FeedBack
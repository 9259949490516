import PropTypes from "prop-types";
import React, { useEffect } from "react";
import { connect } from "react-redux";
import withRouter from "components/Common/withRouter";
import "./Sidebar.css";
//i18n
import { withTranslation } from "react-i18next";
import SidebarContent from "./SidebarContent";

import { Link } from "react-router-dom";

import logo from "../../assets/images/logo.svg";
import logoLightPng from "../../assets/images/logo-light.png";
import logoLightSvg from "../../assets/images/logo-light.svg";
import logoDark from "../../assets/images/logo-dark.png";
import { useDispatch } from "react-redux";
import { getSchoolInfo } from "store/actions";
import { useSelector } from "react-redux";

const Sidebar = props => {
  const dispatch = useDispatch()

  const { schoolInfo, Loading } = useSelector(state => state.SchoolInfoReducer)

  useEffect(() => {
    dispatch(getSchoolInfo())
  }, [])
  return (
    <React.Fragment>
      <div className="vertical-menu">
        <div className="LogoContainer">
          <img src={schoolInfo.school_logo} alt={schoolInfo.name} className="schoolLogo"/>
          <span className="d-block text-white" style={{whiteSpace:"pre-line"}} dangerouslySetInnerHTML={{ __html: schoolInfo.name }} />
        </div>
        <div data-simplebar className=""style={{height:"80vh"}} >
          {props.type !== "condensed" ? <SidebarContent /> : <SidebarContent />}
        </div>
        <div className="sidebar-background"></div>
      </div>
    </React.Fragment>
  );
};

Sidebar.propTypes = {
  type: PropTypes.string,
};

const mapStatetoProps = state => {
  return {
    layout: state.Layout,
  };
};
export default connect(
  mapStatetoProps,
  {}
)(withRouter(withTranslation()(Sidebar)));

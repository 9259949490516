import { all, fork } from "redux-saga/effects";

//public
import AccountSaga from "./auth/register/saga";
import AuthSaga from "./auth/login/saga";
import ForgetSaga from "./auth/forgetpwd/saga";
import ProfileSaga from "./auth/profile/saga";
import LayoutSaga from "./layout/saga";

//Protected
import DashboardSaga from "./Dashboard/saga";
import NotificationsSaga from "./Notifications/sagas";
import FeedbackSaga from "./FeedBack/sagas";
import SchoolInfoSaga from "./GetSchoolInfo/sagas";
import TimeTableSaga from "./TimeTable/sagas";
import FeesSaga from "./Fees/sagas";
import CommonSaga from "./Common/Saga";
import AttendanceSaga from "./Attendance/sagas";

export default function* rootSaga() {
  yield all([
    //public
    fork(AccountSaga),
    fork(AuthSaga),
    fork(ForgetSaga),
    fork(ProfileSaga),
    fork(LayoutSaga),

    //Protected

    fork(DashboardSaga),
    fork(NotificationsSaga),
    fork(FeedbackSaga),
    fork(SchoolInfoSaga),
    fork(TimeTableSaga),
    fork(FeesSaga),
    fork(CommonSaga),
    fork(AttendanceSaga),
  ]);
}

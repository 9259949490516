import { combineReducers } from "redux";

// Front
import Layout from "./layout/reducer";

// Authentication
import Login from "./auth/login/reducer";
import Account from "./auth/register/reducer";
import ForgetPassword from "./auth/forgetpwd/reducer";
import Profile from "./auth/profile/reducer";

//DashBoard
import DashReducer from "./Dashboard/reducer";
import NotificationReducer from "./Notifications/reducer";
import FeedBackReducer from "./FeedBack/reducer";
import SchoolInfoReducer from "./GetSchoolInfo/reducer";
import TimeTableReducer from "./TimeTable/reducer";
import FeesReducer from "./Fees/reducer";
import CommonReducer from "./Common/Reducer";
import AttendanceReducer from "./Attendance/reducer";

const rootReducer = combineReducers({
  // public
  Layout,
  Login,
  Account,
  ForgetPassword,
  Profile,

  DashReducer,
  NotificationReducer,
  FeedBackReducer,
  SchoolInfoReducer,
  TimeTableReducer,
  FeesReducer,
  CommonReducer,
  AttendanceReducer
});

export default rootReducer;

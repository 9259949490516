import axios from "axios"

const liveApiUrl = process.env.REACT_APP_LIVE_API_URL

export const getNotificationsAPI = async () => {
    try {

        let apiToken = JSON.parse(localStorage.getItem("Token"))
        const headers = {
            "Access-Control-Allow-Origin": "*",
            "Content-Type": "application/json",
            Authorization: apiToken,
            Accept: "application/json",
            "Cache-Control": "no-cache",
        }

        const response = await axios.get(`${liveApiUrl}/api/notification/get-top-school-notification`, {
            headers,
        })
        return response
    } catch (error) {
        console.error("Error Gettting Notifications:", error)
        throw error
    }
}


export const getTecherNotificationAPI = async (payload) => {
    try {

        let apiToken = JSON.parse(localStorage.getItem("Token"))
        const headers = {
            "Access-Control-Allow-Origin": "*",
            "Content-Type": "application/json",
            Authorization: apiToken,
            Accept: "application/json",
            "Cache-Control": "no-cache",
        }
        if (payload === 2) {
            return await axios.get(`${liveApiUrl}/api/notification/get-top-school-teachernotification`, {
                headers,
            })
        } else {
            return await axios.get(`${liveApiUrl}/api/notification/get-top-school-defaultnotification`, {
                headers,
            })
        }


        // return response
    } catch (error) {
        console.error("Error Gettting Notifications:", error)
        throw error
    }
}


export const getClassDivAPI = async () => {
    try {

        let apiToken = JSON.parse(localStorage.getItem("Token"))
        const headers = {
            "Access-Control-Allow-Origin": "*",
            "Content-Type": "application/json",
            Authorization: apiToken,
            Accept: "application/json",
            "Cache-Control": "no-cache",
        }

        const response = await axios.get(`${liveApiUrl}/api/notification/get-class-div`, {
            headers,
        })
        return response
    } catch (error) {
        console.error("Error Gettting Dashboard Icons:", error)
        throw error
    }
}


export const getStudListAPI = async () => {
    try {

        let apiToken = JSON.parse(localStorage.getItem("Token"))
        const headers = {
            "Access-Control-Allow-Origin": "*",
            "Content-Type": "application/json",
            Authorization: apiToken,
            Accept: "application/json",
            "Cache-Control": "no-cache",
        }

        const response = await axios.get(`${liveApiUrl}/api/notification/get-student_list`, {
            headers,
        })
        return response
    } catch (error) {
        console.error("Error Gettting Dashboard Icons:", error)
        throw error
    }
}


export const createNotificationAPI = async (payload) => {
    try {

        let apiToken = JSON.parse(localStorage.getItem("Token"))
        const headers = {
            "Access-Control-Allow-Origin": "*",
           // "Content-Type": "multipart/form-data",
            "Content-Type": "application/json",
            Authorization: apiToken,
            Accept: "application/json",
            "Cache-Control": "no-cache",
        }

        const formData = new FormData();

        // formData.append("classdiv_id", `[${payload.classdiv_id}]`);
        formData.append("classdiv_id", `[27]`);
        formData.append("anntext", payload.anntext);
        formData.append("txt_typ", payload.txt_typ);
        formData.append("ann_type", payload.ann_type);
        formData.append("acad_id", payload.acad_id);
        formData.append("sender_IP_adress", payload.sender_IP_adress);
        formData.append("an_email", payload.an_email);
        formData.append("an_app", payload.an_app);
        formData.append("an_web", payload.an_web);
        formData.append("an_sms", payload.an_sms);
        formData.append("files", payload.file_attach);
        // formData.append("file_attach", payload.file_attach);
        // formData.append("thumbnail", payload.thumbnail);
        // formData.append("file_attach2", payload.file_attach2);
        // formData.append("thumbnail2", payload.thumbnail2);
        // formData.append("file_attach3", payload.file_attach3);
        // formData.append("thumbnail3", payload.thumbnail3);

        const response = await axios.post(`${liveApiUrl}/api/notification/create-notification`, payload, {
            headers,
        })
        return response
    } catch (error) {
        console.error("Error Gettting Dashboard Icons:", error)
        throw error
    }
}

import React, { useState, useEffect } from 'react'
import { Container, Form, FormGroup, Input, Label, FormFeedback, Table, Button, Spinner } from 'reactstrap'
import * as Yup from "yup";
import { useFormik } from "formik";
import { useDispatch, useSelector } from 'react-redux';
import { getAttendanceDiv, getAttendStudList, getCurrentAcadid, markAbsentStud } from 'store/actions';
import { Tbody, Thead } from 'react-super-responsive-table';
import Spinners from 'components/Common/Spinner';
import { toast } from 'react-toastify';
const Attendance = () => {

    const dispatch = useDispatch();

    const { divisions, studList, Loading, errors, successMsg } = useSelector(state => state.AttendanceReducer);

    const { acadID } = useSelector(state => state.CommonReducer);

    const [inputData, setInputData] = useState({
        acadid: "",
        classdivid: "",
        abdate: "",
        sortOrderSelect: "",
        sortorderall: "",
        namesequence: "ssi.lname ,\" \",ssi.fname,\" \", ssi.mname"
    })

    const [checkBox, setCheckBox] = useState({
        webCheck: 0,
        appCheck: 0,
        emailCheck: 0,
        smsCheck: 0
    })

    const [abStudData, setAbStudData] = useState({
        allStdUserIdsArr: [],
        abStdUserIdsArr: [],
        destination: []
    })

    const [checkboxesList, setCheckboxesList] = useState({});


    const handleCheckBox = (inputCheck) => {
        console.log(inputCheck.target.checked)
        setCheckBox({ ...checkBox, [inputCheck.target.name]: inputCheck.target.checked ? 1 : 0 })
    }

    const handleInputChange = (inputs) => {
        setInputData({
            ...inputData,
            [inputs.target.name]: inputs.target.value
        })
    }
    const handleStudCheck = (checkInput) => {
        if (checkInput.target.name === "selectAllStud") {
            const newCheckboxes = {};
            if (checkInput.target.checked) {
                studList.forEach(stud => {
                    newCheckboxes[stud.user_id] = true;
                });
            } else {
                studList.forEach(stud => {
                    newCheckboxes[stud.user_id] = false;
                });
            }
            setCheckboxesList(newCheckboxes);
            setAbStudData({
                ...abStudData,
                abStdUserIdsArr: checkInput.target.checked ? InsertStudAllToArray(studList) : []
            });
        } else {
            const newCheckboxes = {
                ...checkboxesList,
                [checkInput.target.value]: checkInput.target.checked
            };
            setCheckboxesList(newCheckboxes);
            if (checkInput.target.checked) {
                setAbStudData(prevState => ({
                    ...prevState,
                    abStdUserIdsArr: [...(prevState.abStdUserIdsArr || []), checkInput.target.value]
                }));
            } else {
                setAbStudData(prevState => ({
                    ...prevState,
                    abStdUserIdsArr: (prevState.abStdUserIdsArr || []).filter(id => id !== checkInput.target.value)
                }));
            }
        }
    };
    




    const InsertStudAllToArray = () => {
        let AllStudents = []
        for (let i = 0; i < studList.length; i++) {
            AllStudents.push(studList[i].user_id)
        }
        return AllStudents
    }


    const submitData = () => {
        console.log(abStudData)
        dispatch(markAbsentStud({...inputData,...abStudData}))
    }

    useEffect(() => {
        if (errors && errors !== null) {
            toast.error(errors)
        }
        if (successMsg && successMsg !== null) {
            toast.success(successMsg)
        }
    }, [errors, successMsg])

    useEffect(() => {
        if (
            inputData.acadid !== "" &&
            inputData.classdivid !== "" &&
            inputData.abdate !== "" &&
            inputData.sortOrderSelect !== ""
        ) {
            dispatch(getAttendStudList(inputData))
        }
    }, [inputData])


    useEffect(() => {
        if (studList && Array.isArray(studList)) {
            const AllStud = InsertStudAllToArray(studList)
            setAbStudData({
                ...abStudData,
                allStdUserIdsArr: AllStud
            })
        }

    }, [studList])

    useEffect(() => {
        if (acadID.acadid) {
            setInputData({ ...inputData, acadid: acadID.acadid })
        }
    }, [acadID])
    useEffect(() => {
        dispatch(getAttendanceDiv())
    }, [dispatch])

    useEffect(() => {
        if (localStorage.getItem("sid")) {
            dispatch(getCurrentAcadid({
                sid: localStorage.getItem("sid")
            }))
        }

    }, [])

    return (
        <div className='page-content px-0 bg-white'>
            <Container fluid={true}>
                <Form
                    onSubmit={(e) => {
                        e.preventDefault();
                        validation.handleSubmit();
                        return false;
                    }}>
                    <FormGroup>
                        <div className="w-100 d-flex justify-content-around px-0 mx-0">
                            <div className="form-check form-switch">
                                <input className="form-check-input" name="webCheck" onClick={(e) => handleCheckBox(e)} type="checkbox" id="WebSelect" />
                                <label className="form-check-label" htmlFor="WebSelect">Web</label>
                            </div>
                            <div className="form-check form-switch">
                                <input className="form-check-input" name="appCheck" onClick={(e) => handleCheckBox(e)} type="checkbox" id="appSelect" />
                                <label className="form-check-label" htmlFor="appSelect">App</label>
                            </div>
                            <div className="form-check form-switch">
                                <input className="form-check-input" name="emailCheck" onClick={(e) => handleCheckBox(e)} type="checkbox" id="emailSelect" />
                                <label className="form-check-label" htmlFor="emailSelect">Email</label>
                            </div>
                            <div className="form-check form-switch">
                                <input className="form-check-input" name="smsCheck" onClick={(e) => handleCheckBox(e)} type="checkbox" id="smsSelect" />
                                <label className="form-check-label" htmlFor="smsSelect">Sms</label>
                            </div>
                        </div>

                    </FormGroup>

                    <FormGroup>
                        <Label htmlFor="Selectteachers" className="form-label">
                            Class Division
                        </Label>
                        <Input
                            id="Selectteachers"
                            name="classdivid"
                            type="select"
                            className="form-control"
                            onChange={handleInputChange}
                        >
                            <option value={""}>
                                Select Divisions
                            </option>
                            {
                                divisions && Array.isArray(divisions) &&
                                divisions.map((classDiv, index) => {
                                    return (
                                        <option value={classDiv.id} key={index}>
                                            {classDiv.div_name}
                                        </option>
                                    )
                                })
                            }
                        </Input>
                    </FormGroup>
                    <FormGroup>
                        <Label htmlFor="selectSort" className="form-label">
                            Sort Order
                        </Label>
                        <Input
                            id="selectSort"
                            name="sortOrderSelect"
                            type="select"
                            className="form-control"
                            onChange={handleInputChange}
                        >
                            <option value={""}>
                                Sort Order
                            </option>

                            <option value={"1001"}>Roll No, Gender, Name</option>
                            <option value={"1002"}>Gender</option>
                            <option value={"1003"}>Name</option>
                            <option value={"1004"}>First Name</option>
                            <option value={"1005"}>Gr. No</option>

                        </Input>
                    </FormGroup>


                    <FormGroup>
                        <Label htmlFor="selectdate" className="form-label">
                            Absent Date
                        </Label>
                        <Input
                            id="selectdate"
                            name="abdate"
                            type="date"
                            className="form-control"
                            onChange={handleInputChange}
                        />
                    </FormGroup>

                </Form>
                {
                    Loading ?
                        <div className='text-center'>

                            <Spinner />
                        </div> :
                        studList && Array.isArray(studList) && studList.length > 0 &&
                        <>
                            <Table className='w-100'>
                                <Thead>
                                    <tr>
                                        <th>
                                            <div className="form-check">
                                                <input
                                                    className="form-check-input"
                                                    name='selectAllStud'
                                                    type="checkbox"
                                                    value=""
                                                    id="flexCheckChecked"
                                                    onChange={handleStudCheck}
                                                    checked={Object.keys(checkboxesList).length !== 0 ?Object.values(checkboxesList).every(val => val):false}
                                                />
                                            </div>
                                        </th>
                                        <th>Roll No</th>
                                        <th>Name</th>
                                    </tr>
                                </Thead>
                                <Tbody>
                                    {studList.map((data, index) => (
                                        <tr key={index}>
                                            <td>
                                                <div className="form-check">
                                                    <input
                                                        className="form-check-input"
                                                        type="checkbox"
                                                        value={data.user_id}
                                                        checked={checkboxesList[data.user_id] || false}
                                                        onChange={handleStudCheck}
                                                        id={`flexCheckChecked${index}`}
                                                    />
                                                </div>
                                            </td>
                                            <td>{data.roll_no}</td>
                                            <td>{data.name}</td>
                                        </tr>
                                    ))}
                                </Tbody>
                            </Table>
                            <Button color={"primary"} onClick={submitData}>Submit</Button>
                        </>
                }
            </Container>

        </div>
    )
}

export default Attendance
// Formik validation
import * as Yup from "yup";
import { useFormik } from "formik";

import React, { useEffect, useState } from 'react'
import { useDispatch } from 'react-redux'

import { Container, FormGroup, Input, Label, Form, Button, Card, CardBody, FormFeedback } from 'reactstrap'
import { createFeedback, createNotification, getClassDiv, getCurrentAcadid, getStaffList, getStudList } from 'store/actions';
import { useSelector } from "react-redux";
import { toast } from "react-toastify";
import { useNavigate } from "react-router-dom";
import axios from "axios";
import Spinners from "components/Common/Spinner";
import FileUpload from "./FileUpload";

const CreateNotifications = () => {

  const dispatch = useDispatch();
  const navigate = useNavigate();

  const [checkBox, setCheckBox] = useState({
    webCheck: 0,
    appCheck: 0,
    emailCheck: 0,
    smsCheck: 0
  })
  const [uploadFiles, setUploadFiles] = useState()
  const [ip, setIp] = useState('');
  const { Loading, errors, classDivList, studList, successMsg } = useSelector(state => state.NotificationReducer);
  const { acadID } = useSelector(state => state.CommonReducer);

  
  useEffect(() => {
    if (errors && errors !== null) {
      toast.error(errors)
    }
    if (successMsg && successMsg !== null) {
      toast.success(successMsg)
      validation.resetForm();
    }

  }, [errors, successMsg])

  const handleCheckBox = (inputCheck) => {
    console.log(inputCheck.target.checked)
    setCheckBox({ ...checkBox, [inputCheck.target.name]: inputCheck.target.checked ? 1 : 0 })
  }

  const validation = useFormik({
    // enableReinitialize : use this  flag when initial values needs to be changed
    enableReinitialize: true,

    initialValues: {
      "classdiv_id": [],
      "anntext": "",
      "txt_typ": "English",
      "ann_type": "",
      "acad_id": acadID.acadid,
      "sender_IP_adress": ip
    },
    validationSchema: Yup.object({
      ann_type: Yup.string().required("Please Select Announcement type"),
      // selectTeach: Yup.string().required("Please Select Reciptent"),
      // classdiv_id: Yup.array().required("Please Select Subject"),
      anntext: Yup.string().required("Please Enter text"),
    }),
    onSubmit: (values) => {

      dispatch(createNotification({
        ...values,
        ...uploadFiles,
        an_email: checkBox.emailCheck,
        an_app: checkBox.appCheck,
        an_web: checkBox.webCheck,
        an_sms: checkBox.smsCheck
      }))

      console.log({
        ...values,
        ...uploadFiles,
        an_email: checkBox.emailCheck,
        an_app: checkBox.appCheck,
        an_web: checkBox.webCheck,
        an_sms: checkBox.smsCheck
      })

      //  dispatch(createNotification({
      //    "classdiv_id": [27],
      //    "anntext": "test jsfksdfb sdfksfjsdfksd sdfjdsfjkdsb sdjkfsbdfjbsd ds",
      //    "txt_typ": "English",
      //    "ann_type": "1",
      //    "file_attach": "",
      //    "thumbnail": " ",
      //    "file_attach2": " ",
      //    "thumbnail2": "",
      //    "file_attach3": "",
      //    "thumbnail3": "",
      //    "acad_id": 31,
      //    "sender_IP_adress": "435566",
      //    "an_email": 1,
      //    "an_app": 1,
      //    "an_web": 1

      //  }))
    }
  });



  useEffect(() => {
    const fetchIp = async () => {
      try {
        const response = await axios.get('https://api.ipify.org?format=json');
        console.log(response.data.ip)
        setIp(response.data.ip);
      } catch (error) {
        console.error('Error fetching the IP address:', error);
      }
    };

    fetchIp();
  }, []);

  useEffect(() => {
    dispatch(getClassDiv())
    dispatch(getStudList())
  }, [])
  useEffect(() => {
    if (localStorage.getItem("sid")) {
      dispatch(getCurrentAcadid({
        sid: localStorage.getItem("sid")
      }))
    }else{
      toast.error("SID missing")
    }

  }, [])
  return (
    <div className='page-content px-0'>

      {
        Loading ?
          <Spinners />
          :

          <Card className="py-3 px-2 mx-0">


            <Form
              className="form-horizontal"
              onSubmit={(e) => {
                e.preventDefault();
                validation.handleSubmit();
                return false;
              }}>

              <FormGroup>
                <div className="w-100 d-flex justify-content-around px-0 mx-0">
                  <div className="form-check form-switch">
                    <input className="form-check-input" name="webCheck" onClick={(e) => handleCheckBox(e)} type="checkbox" id="WebSelect" />
                    <label className="form-check-label" htmlFor="WebSelect">Web</label>
                  </div>
                  <div className="form-check form-switch">
                    <input className="form-check-input" name="appCheck" onClick={(e) => handleCheckBox(e)} type="checkbox" id="appSelect" />
                    <label className="form-check-label" htmlFor="appSelect">App</label>
                  </div>
                  <div className="form-check form-switch">
                    <input className="form-check-input" name="emailCheck" onClick={(e) => handleCheckBox(e)} type="checkbox" id="emailSelect" />
                    <label className="form-check-label" htmlFor="emailSelect">Email</label>
                  </div>
                  <div className="form-check form-switch">
                    <input className="form-check-input" name="smsCheck" onClick={(e) => handleCheckBox(e)} type="checkbox" id="smsSelect" />
                    <label className="form-check-label" htmlFor="smsSelect">Sms</label>
                  </div>
                </div>

              </FormGroup>


              <FormGroup>
                <Label htmlFor="SelectAnnType" className="form-label">
                  Announcement Type
                </Label>
                <Input
                  id="SelectAnnType"
                  name="ann_type"
                  type="select"
                  className="form-control"
                  onChange={validation.handleChange}
                  onBlur={validation.handleBlur}
                  value={validation.values.ann_type || ""}
                  invalid={
                    validation.touched.ann_type && validation.errors.ann_type ? true : false
                  }
                >
                  <option value={""}>
                    Select Announcement Type
                  </option>

                  <option value={6}>
                    Homework/Activity
                  </option>

                  <option value={3}>
                    Student Specific
                  </option>

                  {/* <option value={2}>
                    Announcement
                  </option> */}
                </Input>
                {validation.touched.ann_type && validation.errors.ann_type ? (
                  <FormFeedback type="invalid">{validation.errors.ann_type}</FormFeedback>
                ) : null}
              </FormGroup>

              <FormGroup>
                <Label htmlFor="Selectteachers" className="form-label">
                  Class Division
                </Label>
                <Input
                  id="Selectteachers"
                  multiple={true}
                  name="classdiv_id"
                  type="select"
                  className="form-control"
                  onChange={validation.handleChange}
                  onBlur={validation.handleBlur}
                  value={validation.values.classdiv_id || ""}
                  invalid={
                    validation.touched.classdiv_id && validation.errors.classdiv_id ? true : false
                  }
                >
                  <option value={""}>
                    Select Divisions
                  </option>
                  {
                    classDivList && Array.isArray(classDivList) &&
                    classDivList.map((classDiv, index) => {
                      return (
                        <option value={classDiv.id} key={index}>
                          {classDiv.div_name}
                        </option>
                      )
                    })
                  }
                </Input>
                {validation.touched.classdiv_id && validation.errors.classdiv_id ? (
                  <FormFeedback type="invalid">{validation.errors.classdiv_id}</FormFeedback>
                ) : null}
              </FormGroup>

              {/* <FormGroup>
                <Label htmlFor="SubjectInput" className="form-label">Students</Label>
                <Input
                  id="SubjectInput"
                  name="selectSub"
                  type="select"
                  placeholder="Select Subject"
                  className="form-control"
                  onChange={validation.handleChange}
                  onBlur={validation.handleBlur}
                  value={validation.values.selectSub || ""}
                  invalid={
                    validation.touched.selectSub && validation.errors.selectSub ? true : false
                  }
                >
                  <option value={null}>
                    Select Student
                  </option>
                  {
                    studList && Array.isArray(studList) && studList.map((student, index) => (
                      <option value={student.user_id} key={index}>
                        {student.fname}
                      </option>
                    ))
                  }

                </Input>
                {validation.touched.selectSub && validation.errors.selectSub ? (
                  <FormFeedback type="invalid">{validation.errors.selectSub}</FormFeedback>
                ) : null}
              </FormGroup>  */}

              <FormGroup>
                <Label htmlFor="selectLang" className="form-label">
                  Language
                </Label>
                <Input
                  id="selectLang"
                  name="txt_typ"
                  type="select"
                  className="form-control"
                  onChange={validation.handleChange}
                  onBlur={validation.handleBlur}
                  value={validation.values.txt_typ || ""}
                  invalid={
                    validation.touched.txt_typ && validation.errors.txt_typ ? true : false
                  }
                >
                  <option value={"English"}>
                    English
                  </option>

                  <option value={"Other Language"}>
                    Other
                  </option>
                </Input>
                {validation.touched.txt_typ && validation.errors.txt_typ ? (
                  <FormFeedback type="invalid">{validation.errors.txt_typ}</FormFeedback>
                ) : null}
              </FormGroup>

              <FormGroup>
                <div>
                  <Label htmlFor="queryMsg" className="form-label">Enter Notification text</Label>
                  <Input
                    name='anntext'
                    type='textarea'
                    id='queryMsg'
                    className="form-control"
                    onChange={validation.handleChange}
                    onBlur={validation.handleBlur}
                    value={validation.values.anntext || ""}
                    invalid={
                      validation.touched.anntext && validation.errors.anntext ? true : false
                    } />
                  {validation.touched.anntext && validation.errors.anntext ? (
                    <FormFeedback type="invalid">{validation.errors.anntext}</FormFeedback>
                  ) : null}
                </div>

                <div>
                  <FileUpload setUploadFiles={setUploadFiles} />
                </div>
              </FormGroup>



              <FormGroup className='text-center'>
                <Button type='submit' color='primary' className='btn-rounded form-control'>Submit</Button>
              </FormGroup>
            </Form>

          </Card>
      }

    </div>
  )
}

export default CreateNotifications